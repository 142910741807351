import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import { EVENT_CREATE_RESET } from '../constants/eventConstants'
import { useNavigate } from 'react-router-dom'
import { createEvent } from '../actions/eventActions'
import axios from 'axios'
import delay from '../utils/delay'
import toast from 'react-hot-toast'

const EventModal = () => {
    const nav = useNavigate();

    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [description, setDescription] = useState('')
    const [location, setLocation] = useState('')
    const [startDate, setStartDate] = useState('')
    const [category, setCategory] = useState('')
    const [endDate, setEndDate] = useState('')
    const [url, setUrl] = useState('')
    const [uploading, setUploading] = useState(false)

    const dispatch = useDispatch()

    const eventDetails = useSelector((state) => state.eventDetails)
    const { loading, error, event } = eventDetails

    const eventCreate = useSelector((state) => state.eventCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        event: createdEvent,
    } = eventCreate

    useEffect(() => {
        if (successCreate) {
            dispatch({ type: EVENT_CREATE_RESET })
            nav('/')
        }
    }, [dispatch, event, successCreate])

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image', file)
        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            const { data } = await axios.post('/api/upload', formData, config)

            setImage(data)
            setUploading(false)
        } catch (error) {
            console.error(error)
            setUploading(false)
        }
    }

    const handleOptionChange = (e) => {
        setCategory(e.target.value);
    }

    const submitHandler = (e) => {
        //e.preventDefault()
        dispatch(
            createEvent({
                title,
                image,
                description,
                category,
                location,
                startDate,
                endDate,
                url,
            })
        )
        if (successCreate) {
            async function completeRequest() {
                toast.success("Event successfully created!")
                await delay(3000)
                nav('/')
            }
            completeRequest()
        }
    }

    return (
        <div className="modal" id="eventModal">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">Add/Edit Event</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <form onSubmit={submitHandler}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div><label className="form-label">Event Type</label></div>
                                    <div className="d-flex">
                                    <div className="n-chk">
                                            <div className="form-check form-check-danger form-check-inline">
                                                <input className="form-check-input" type="radio" onChange={(e) => setCategory(e.target.value)} name="event-level" value="meeting" id="modalDefault" />
                                                <label className="form-check-label" htmlFor="modalDefault">Lekgotla</label>
                                            </div>
                                        </div>
                                        <div className="n-chk">
                                            <div className="form-check form-check-primary form-check-inline">
                                                <input className="form-check-input" type="radio" onChange={(e) => setCategory(e.target.value)} name="event-level" value="birthday" id="modalDanger" />
                                                <label className="form-check-label" htmlFor="modalDanger">Birthday</label>
                                            </div>
                                        </div>
                                        <div className="n-chk">
                                            <div className="form-check form-check-warning form-check-inline">
                                                <input className="form-check-input" type="radio" onChange={(e) => setCategory(e.target.value)} name="event-level" value="anniversary" id="modalSuccess" />
                                                <label className="form-check-label" htmlFor="modalSuccess">Marriage Annivesary</label>
                                            </div>
                                        </div>
                                        <div className="n-chk">
                                            <div className="form-check form-check-success form-check-inline">
                                                <input className="form-check-input" type="radio" onChange={(e) => setCategory(e.target.value)} name="event-level" value="graduation" id="modalPrimary" />
                                                <label className="form-check-label" htmlFor="modalPrimary">Graduation</label>
                                            </div>
                                        </div>
                                        <div className="n-chk">
                                            <div className="form-check form-check-danger form-check-inline">
                                                <input className="form-check-input" type="radio" onChange={(e) => setCategory(e.target.value)} name="event-level" value="shower" id="modalWarning" />
                                                <label className="form-check-label" htmlFor="modalWarning">Baby Shower</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 mt-4">
                                    <div className="">
                                        <label className="form-label">Event Title</label>
                                        <input id="event-title" value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4">
                                    <div className="">
                                        <label className="form-label">From</label>
                                        <input id="event-start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="form-control" />
                                    </div>
                                </div>

                                <div className="col-md-6 mt-4">
                                    <div className="">
                                        <label className="form-label">To</label>
                                        <input id="event-end-date" value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary btn-add-event" style={{ display: `block` }}>
                                Add Event
                            </button>
                        </div>
                    </form>



                </div>
            </div>
        </div>
    )
}

export default EventModal