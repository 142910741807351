import React, { useEffect, useState } from 'react'
import Calendar from 'react-awesome-calendar'
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {
    listEvents
} from '../actions/eventActions'
import { EVENT_CREATE_RESET } from '../constants/eventConstants'
import EventModal from '../components/EventModal.js'
import Loader from '../components/Loader';

// const events = [{
//     id: 1,
//     color: '#fd3153',
//     from: '2023-08-19T18:00:00+00:00',
//     to: '2023-08-19T19:00:00+00:00',
//     title: 'This is an event'
// }, {
//     id: 2,
//     color: '#1ccb9e',
//     from: '2023-08-18T13:00:00+00:00',
//     to: '2023-08-18T14:00:00+00:00',
//     title: 'This is another event'
// }, {
//     id: 3,
//     color: '#3694DF',
//     from: '2023-08-20T13:00:00+00:00',
//     to: '2023-08-20T20:00:00+00:00',
//     title: 'This is also another event'
// }];

const Home = () => {
    const pageNumber = 1
    const nav = useNavigate();
    const dispatch = useDispatch()
    const [eventsList, setEventsList] = useState([])

    const eventList = useSelector((state) => state.eventList)
    const { loading, error, events, page, pages } = eventList

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        dispatch({ type: EVENT_CREATE_RESET })
        dispatch(listEvents('', pageNumber))

        // setEventsList(
        //     events?.map((event, index) => {
        //         return {
        //             id: (index + 1),
        //             color: '#1ccb9e',
        //             from: event.startDate,
        //             to: event.endDate,
        //             title: event.title
        //         }
        //     })
        // );

        // if(pages ){
        //     console.log(pages)
        // }

    }, [dispatch, pageNumber])




    return (
        <div>

            {loading ? <Loader /> :
                <>
                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#eventModal">
                        Add Event
                    </button>
                    <Calendar events={events?.map((event, index) => {
                        return {
                            id: (index + 1),
                            color: '#1ccb9e',
                            from: event.startDate,
                            to: event.endDate,
                            title: event.title
                        }
                    })
                    } />
                    <EventModal />
                </>}
        </div>
    )
}

function CalendarHeader({ props }) {

    return (
        <div className="calendarHeader"><h1><span className="thickText"><Moment format="DD MMMM">{new Date()}</Moment></span>&nbsp;<span className="thinText"><Moment format="YYYY">{new Date()}</Moment></span></h1><div className="calendarHeaderButtons"><button><span>
            <i className="ti ti-square-rounded-chevron-left"></i>
        </span> <Moment format="MMMM">{new Date().setMonth(new Date().getMonth() - 1)}</Moment></button><button><Moment format="MMMM">{new Date().setMonth(new Date().getMonth() + 1)}</Moment> <span>
            <i className="ti ti-square-rounded-chevron-right"></i>
        </span></button></div></div>
    )
}

export default Home