import React from 'react'

const Photos = () => {
    return (
        <div>
            <div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title fw-semibold mb-4">Photos</h5>
                        <p className="mb-0">Family photos will soon be visible here </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Photos