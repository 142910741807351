const members = [
  {
    "name": "Adna Ntuli",
    "birthday": "1990-05-15",
    "email": ""
  },
  {
    "name": "Armstrong Mataboge",
    "birthday": "1985-12-10",
    "email": "armstrong.mataboge@transnet.net"
  },
  {
    "name": "Armstrong Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Dineo Mphahlele",
    "birthday": "",
    "email": "dineomphahlele@icloud.com"
  },
  {
    "name": "Hanyane Kgetja Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Hlamalane Chauke",
    "birthday": "",
    "email": "chaukeh44@gmail.com"
  },
  {
    "name": "Iggy Mphahlele",
    "birthday": "",
    "email": "Iggyse9@gmail.com"
  },
  {
    "name": "Khotso Mphahlele",
    "birthday": "",
    "email": "khotso7@gmail.com"
  },
  {
    "name": "Kutlwano Mphahlele",
    "birthday": "",
    "email": "kutlwanom295@gmail.com"
  },
  {
    "name": "Lebogang Petlele",
    "birthday": "",
    "email": "lebogangpetlele@gmail.com"
  },
  {
    "name": "Lehlogonolo Pududu",
    "birthday": "",
    "email": "lolo.pududu@live.co.za"
  },
  {
    "name": "Lesedi Mphahlele",
    "birthday": "",
    "email": "sedi.mphahlele@gmail.com"
  },
  {
    "name": "Lesego Sambo",
    "birthday": "",
    "email": "sambolesegos@gmail.com"
  },
  {
    "name": "Maleshoane Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Mante Mphahlele",
    "birthday": "",
    "email": "mante@meladi.co.za"
  },
  {
    "name": "Marwick Ratau Mphahlele",
    "birthday": "",
    "email": "ratau@barokafunerals.co.za"
  },
  {
    "name": "Maureen Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Mercy Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Molemo Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "More Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Motshabi Mphahlele",
    "birthday": "",
    "email": "motshabikm@gmail.com"
  },
  {
    "name": "Motswiri Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Mpho Mphahlele",
    "birthday": "",
    "email": "Mphomphahlele332@gmail.com"
  },
  {
    "name": "Namedi (Bafana) Sullivan Mphahlele",
    "birthday": "28/11/2023",
    "email": "sullivanm@vodamail.co.za"
  },
  {
    "name": "Namedi Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Namedi Tebogo Mataboge",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Nhlanhla Ntuli",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Oscar Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Paulina Mataboge",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Pebetsi Chauke",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Puleng Matsose",
    "birthday": "",
    "email": "phmatsose@mweb.co.za"
  },
  {
    "name": "Ruby Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Shisha Theresa Puoane",
    "birthday": "",
    "email": "therrypuoane@gmail.com"
  },
  {
    "name": "Thabiso Mallane Mphahlele",
    "birthday": "",
    "email": "tmphahlele27@icloud.com"
  },
  {
    "name": "Thabo Ngwedi Puoane",
    "birthday": "",
    "email": "thabosteak3@gmail.com"
  },
  {
    "name": "Thato Matsose",
    "birthday": "",
    "email": "tmatsose@gmail.com"
  },
  {
    "name": "Thato Mogalatjane Mphahlele",
    "birthday": "",
    "email": "innovatet@gmail.com"
  },
  {
    "name": "Tshegofatso Kopano Matsose",
    "birthday": "",
    "email": "kopanomatsose@gmail.com"
  },
  {
    "name": "Tshepo Matsose",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Tshepo Mofokeng",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Tumelo Matsose",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Tutu Puoane",
    "birthday": "",
    "email": "tutupuoane@gmail.com"
  },
  {
    "name": "Victor Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Zaza Mphahlele",
    "birthday": "",
    "email": ""
  },
  {
    "name": "Ratau Mphahlele",
    "birthday": "",
    "email": "rataum@webmail.co.za"
  }
]

export default members