import React from 'react'
import members from '../lookups/members'

const Members = () => {
    return (
        <div>
            <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                <div className="card-body px-4 py-3">
                    <div className="row align-items-center">
                        <div className="col-9">
                            <h4 className="fw-semibold mb-8">Members</h4>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a className="text-muted " href="/">Dashboard</a></li>
                                    <li className="breadcrumb-item" aria-current="page">Contact</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-3">
                            <div className="text-center mb-n5">
                                <img src="https://demos.adminmart.com/premium/bootstrap/modernize-bootstrap/package/dist/images/breadcrumb/ChatBc.png" alt="" className="img-fluid mb-n4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="widget-content searchable-container list">
                {/* <div className="card card-body">
                    <div className="row">
                        <div className="col-md-4 col-xl-3">
                            <form className="position-relative">
                                <input type="text" className="form-control product-search ps-5" id="input-search" placeholder="Search Contacts..." />
                                <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                            </form>
                        </div>
                        <div className="col-md-8 col-xl-9 text-end d-flex justify-content-md-end justify-content-center mt-3 mt-md-0">
                            <div className="action-btn show-btn" style={{ display: `none` }}>
                                <a href="#" className="delete-multiple btn-light-danger btn me-2 text-danger d-flex align-items-center font-medium">
                                    <i className="ti ti-trash text-danger me-1 fs-5"></i> Delete All Row
                                </a>
                            </div>
                            <a href="#" id="btn-add-contact" className="btn btn-info d-flex align-items-center">
                                <i className="ti ti-users text-white me-1 fs-5"></i> Add Contact
                            </a>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="addContactModal" tabindex="-1" role="dialog" aria-labelledby="addContactModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header d-flex align-items-center">
                                <h5 className="modal-title">Contact</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <form id="addContactModalTitle">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3 contact-name">
                                                        <input type="text" id="c-name" className="form-control" placeholder="Name" />
                                                        <span className="validation-text text-danger"></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3 contact-email">
                                                        <input type="text" id="c-email" className="form-control" placeholder="Email" />
                                                        <span className="validation-text text-danger"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3 contact-occupation">
                                                        <input type="text" id="c-occupation" className="form-control" placeholder="Occupation" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3 contact-phone">
                                                        <input type="text" id="c-phone" className="form-control" placeholder="Phone" />
                                                        <span className="validation-text text-danger"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-3 contact-location">
                                                        <input type="text" id="c-location" className="form-control" placeholder="Location" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button id="btn-add" className="btn btn-success rounded-pill px-4">Add</button>
                                <button id="btn-edit" className="btn btn-success rounded-pill px-4">Save</button>
                                <button className="btn btn-danger rounded-pill px-4" data-bs-dismiss="modal"> Discard </button>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="card card-body">
                    <div className="table-responsive">
                        <table className="table search-table align-middle text-nowrap">
                            <thead className="header-item">
                                <tr><th>
                                    <div className="n-chk align-self-center text-center">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input primary" id="contact-check-all" />
                                            <label className="form-check-label" for="contact-check-all"></label>
                                            <span className="new-control-indicator"></span>
                                        </div>
                                    </div>
                                </th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    {/* <th>Phone</th>
                                    <th>Action</th> */}
                                </tr></thead>
                            <tbody>
                                {
                                    members?.map((member) => (
                                        <tr className="search-items">
                                            <td>
                                                <div className="n-chk align-self-center text-center">
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input contact-chkbox primary" id="checkbox1" />
                                                        <label className="form-check-label" for="checkbox1"></label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src="https://static-00.iconduck.com/assets.00/user-avatar-icon-512x512-vufpcmdn.png" alt="avatar" className="rounded-circle" width="35" />
                                                    <div className="ms-3">
                                                        <div className="user-meta-info">
                                                            <h6 className="user-name mb-0" data-name="Emma Adams">{member.name}</h6>
                                                            {/* <span className="user-work fs-3" data-occupation="Web Developer">Web Developer</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className="usr-email-addr" data-email="adams@mail.com">{member.email}</span>
                                            </td>
                                            {/* <td>
                                                <span className="usr-location" data-location="Boston, USA">Boston, USA</span>
                                            </td>
                                            <td>
                                                <span className="usr-ph-no" data-phone="+1 (070) 123-4567">+91 (070) 123-4567</span>
                                            </td>
                                            <td>
                                                <div className="action-btn">
                                                    <a href="#" className="text-info edit">
                                                        <i className="ti ti-eye fs-5"></i>
                                                    </a>
                                                    <a href="#" className="text-dark delete ms-2">
                                                        <i className="ti ti-trash fs-5"></i>
                                                    </a>
                                                </div>
                                            </td> */}
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Members