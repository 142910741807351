import logo from '../images/dark-logo.svg'


const Sidebar = () => {
    return (
        <aside className="left-sidebar">
            <div>
                <div className="brand-logo d-flex align-items-center justify-content-between">
                    <a href="/" className="text-nowrap logo-img">
                        <img src={logo} width="180" alt=""></img>
                    </a>
                    <div className="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                        <i className="ti ti-x fs-8"></i>
                    </div>
                </div>
                <nav className="sidebar-nav scroll-sidebar" data-simplebar="init"><div className="simplebar-wrapper"><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset"><div className="simplebar-content-wrapper" role="region" aria-label="scrollable content"><div className="simplebar-content" >
                    <ul id="sidebarnav">
                        <li className="nav-small-cap">
                            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                            {/* <span className="hide-menu">Hello, Lesego Sambo</span> */}
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/" aria-expanded="false">
                                <span>
                                    <i className="ti ti-layout-dashboard"></i>
                                </span>
                                <span className="hide-menu">Home</span>
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/members" aria-expanded="false">
                                <span>
                                    <i className="ti ti-users"></i>
                                </span>
                                <span className="hide-menu">Members</span>
                            </a>
                        </li>
                        {/* <li className="sidebar-item">
                            <a className="sidebar-link" href="/family-tree" aria-expanded="false">
                                <span>
                                    <i className="ti ti-tree"></i>
                                </span>
                                <span className="hide-menu">Family Tree</span>
                            </a>
                        </li> */}
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="/photos" aria-expanded="false">
                                <span>
                                    <i className="ti ti-brand-google-photos"></i>
                                </span>
                                <span className="hide-menu">Photos</span>
                            </a>
                        </li>

                      
                    </ul>
                </div></div></div></div><div className="simplebar-placeholder"></div></div><div className="simplebar-track simplebar-horizontal"><div className="simplebar-scrollbar"></div></div><div className="simplebar-track simplebar-vertical"><div className="simplebar-scrollbar"></div></div></nav>
            </div>
        </aside>
    );
};

export default Sidebar;