import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Sidebar from './Sidebar';
import Header from './Header';
import { Toaster } from 'react-hot-toast';

const Layout = () => {
    return (
        <div className="page-wrapper mini-sidebar" id="main-wrapper" data-layout="vertical" data-sidebartype="mini-sidebar" data-sidebar-position="fixed" data-header-position="fixed">
            <Toaster />
            <Sidebar />
            <div className="body-wrapper">
                <Header />
                <div className="container-fluid">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout